<template>
  <div :class="`FeedMenu ${$user ? '' : 'public'}`">
    <div class="FeedMenuContent">
      <LargeButton
        icon="ig_video_solid"
        :label="$locale['watch']"
        :class="`${mkLink($links['watch'], 'on')}`"
        @click="goToNav({ view: _view }, $links['watch'])"
      />

      <LargeButton icon="bars-mid" :label="$locale['more']" @click="UserAdminMenu" />

      <div class="FeedMenuBecomeBlock" dpadding v-if="!$isCreator" border-top>
        <UserBecomeCreatorBtn />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["watch", "_view"],
};
</script>
